
<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard class="cards">
          <form id="UpdatePurchaseInvoices" @submit="UpdatePurchaseInvoices" >
          <CCardHeader>
            <CCol col="6" class="styleHeader"> {{ $t('message.updatePurchaseInvoices') }}</CCol>
          </CCardHeader>
           <CCardBody>
            <CRow>
              <CCol sm='6'>
                   <div class='form-group'>
                    <label for='Supplier'>
                       {{ $t('message.supplier') }}
                      <span class='star'>*</span>
                    </label>
                    <div>
                      <multiselect
                        class='required'
                        v-model='selectedSupplier'
                        id='supplier_id'
                        :options='suppliers'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder=" $t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='id'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
                    </div>
                  </div>
              </CCol>
            </CRow>
            <hr>
            <CRow class="mt-3">
              <CCol col="6 m-auto">
                <h4 class="order text-center mb-3">{{$t('message.products')}}</h4>
              </CCol>
            </CRow>
             <CRow class="mt-2">
              <table class="table">
                <thead>
                  <tr>
                 <th width="20%">  {{$t('message.product')}}<span class='star'>*</span> </th>
                  <th width="15%">  {{$t('message.price')}}<span class='star'>*</span></th>
                  <th width="20%"> {{$t('message.quantity')}}<span class='star'>*</span></th>
                  <th width="15%"> {{$t('message.total')}}</th>
                  </tr>
                </thead>
                <tbody>
                 <tr v-for=" product in purchaseInvoices.order.products" :key="product.id">
                    <td>
                      <multiselect
                        class='required'
                        id='product_id'
                        v-model="product.product"
                        :options='productOptions'
                        :searchable='true'
                        v-on:keypress="changeLable($event)"
                        @select="currentProduct($event)"
                       :close-on-select='true'
                       :placeholder="$t('message.select')"
                       :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='id'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>

                    </td>
                    <td>
                      <CInput
                        type='number'
                        id='price'
                        min='0'
                        disabled
                        v-model='product.product.price'
                      />
                    </td>
                    <td>
                       <CInput
                        type='number'
                        id='quantity'
                        class="required"
                        min='0'
                        disabled
                        @input="calcTotal($event,index)"
                        v-on:keyup.enter="submit"
                        v-model='product.quantity'
                        required
                      />
                    </td>
                    <td>
                       <CInput
                        type='number'
                        disabled
                        id='total'
                        min='0'
                        v-model='product.total'
                        required
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CRow>
            <hr>
            <CRow>
            <CCol sm="6">
                 <CInput :label="$t('message.invoicePrice')"
                  id="invoice_price"
                  v-model="purchaseInvoices.price_paid"
                  class="required"
                  :placeholder="$t('message.invoicePrice')"
                  :invalidFeedback="errors.invoicePrice"
                  required
                />
            </CCol>

            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary" >
            <CIcon name="cil-check-circle"/> {{ $t('message.save')}}</CButton>
              &emsp;
          <CButton type="button" size="sm" color="btn btn-primary" @click="goBack">
            <CIcon name="cil-ban"/> {{ $t('message.back') }} </CButton>
          </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'UpdateSalesInvoices',
  data: function () {
    return {
      purchaseInvoices: [],
      products: [],
      sendProducts: [],
      productOptions: [],
      selectedProduct: '',
      suppliers: [],
      order: [],
      selectedSupplier: '',
      errors: []
    }
  },
  created () {
    const suppliers = []
    const productOptions = []
    this.$http
      .get(`${this.$hostUrl}products`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          productOptions.push({ id: value.id, name: value.name, price: value.price })
        })
        this.productOptions = productOptions
      })
    this.$http
      .get(`${this.$hostUrl}suppliers`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          suppliers.push({ id: value.id, name: value.name })
        })

        this.suppliers = suppliers
      })
    const current = this
    this.$http
      .get(`${this.$hostUrl}purchase-invoices/${this.$route.params.id}/edit`)
      .then((response) => {
        this.purchaseInvoices = response.data.data.buy_invoice
        this.selectedSupplier = {
          id: this.purchaseInvoices.supplier.id,
          name: this.purchaseInvoices.supplier.name
        }
        $.each(this.purchaseInvoices.products, function (key, value) {
          current.products.push({
            product: {
              id: value.product.id,
              product_id: value.product.id,
              name: value.product.name,
              price: value.product.price,
              total: value.product.price * value.quantity
            },
            id: value.id,
            quantity: value.quantity
          })
        })
      })
  },
  methods: {
    addProduct () {
      this.products.push({
        product: {
          product_id: null,
          price: null,
          total: null
        },
        id: (this.products.length + 1),
        quantity: null
      })
    },
    removeProduct (index) {
      this.products.splice(index, 1)
    },
    currentProduct ($event) {
      console.log($event)
    },
    calcTotal ($event, index) {
      this.products[index].product.total = $event * this.products[index].product.price
    },
    changeLable (e) {
      const arabic = /[\u0600-\u06FF]/
      let l = ''
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[A-Za-z]+$/.test(char)) l = 'name'
      else if (arabic.test(char)) l = 'name' // Match with regex
      else {
        e.preventDefault()
        l = 'mobile'
      } // If not match, don't add to input text
      return l
    },
    async UpdatePurchaseInvoices (e) {
      e.preventDefault()
      const formData = new FormData()
      if (this.selectedSupplier) {
        formData.append('supplier_id', this.selectedSupplier.id)
      } else {
        this.errors.push('supplier_id')
        this.errors.supplier_id = 'Supplier field is required'
        return 0
      }
      formData.append('price_paid', this.purchaseInvoices.price_paid)
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, `${this.$hostUrl}purchase-invoices/${this.$route.params.id}`, '/purchase-invoices')
    },
    goBack () {
      this.areasOpened ? this.$router.go(-1) : this.$router.push({ path: '/purchase-invoices' })
    }
  }
}

</script>
<style>
.order{
  background-color: #9daec5;
  color: #fff;
  padding: 15px;
  border-radius: 20%;
  font-size: 30px !important;
}
.add{
   background-color: #2a3647 !important;
  color: #fff !important;
  font-size: 15px !important;
}
</style>
